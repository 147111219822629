<template>
  <div class="footer">
    <p id="version">© 2024 Masterwork Aoitek Tech Corp Ltd. {{ version }}</p>
  </div>
</template>
<script>
export default {
  name: 'PageFooter',
  data: () => ({
    version: require('../../package.json').version,
    isCookies: true,
  }),
}
</script>

<style lang="sass" scoped>
.footer
  padding: 3rem 1rem
  background-color: #ffffff
#version
  color: #262626
  text-align: center
</style>
